import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import Img from "gatsby-image";

//ヘッダーのcssを読み込み
import "../styles/style_header2401.css";

const Header = ({ h1data }) => (
  <StaticQuery
    query={graphql`
      {
        datoCmsHome {
          seo {
            title
            description
            twitterCard
          }
          copyright
          logo {
            url
            fluid(maxWidth: 350, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          icon {
            url
            fluid(maxWidth: 300, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    `}
    render={(data) => {
      const homedata = data.datoCmsHome;
      const drawerOpen = () => {
        const drawer = document.querySelector(".lp_container");
        drawer.classList.toggle("open");
      };
      // const h1alt = h1data ? h1data.title : "アヴィエラン"

      return (
        <header>
          <div class="top2401__header">
            <div class="area_logo">
              <h1>
                <Link to="/">
                  <img src="https://www.datocms-assets.com/45449/1702951144-top_header_img_logo.svg" alt="アヴィエラン株式会社 / AVIELAN Co., Ltd." />
                </Link>
              </h1>
            </div>

            <div class="area_nav">
              <ul>
                <li>
                  <Link to="/company/" className="top2401__header_link03">
                    会社概要
                  </Link>
                </li>
                <li>
                  <Link to="/logisticsequipment/" className="top2401__header_link03">
                    物流機器事業本部
                  </Link>
                </li>
                <li>
                  <Link to="/software-division/" className="top2401__header_link03">
                    ソフトウェア事業部
                  </Link>
                </li>
                <li>
                  <Link to="/ec-solution-division/" className="top2401__header_link03">
                    ECソリューション事業部
                  </Link>
                </li>
                <li>
                  <Link to="/iot-division/" className="top2401__header_link03">
                    IoT事業部
                  </Link>
                </li>
                <li>
                  <Link to="/news/" className="top2401__header_link03">
                    ニュースリリース
                  </Link>
                </li>
                <li>
                  <Link to="/recruit/" className="top2401__header_link03">
                    採用情報
                  </Link>
                </li>
                <li>
                  <Link to="/contact/" className="top2401__header_link03">
                    お問い合わせ
                  </Link>
                </li>
              </ul>

              <div class="box_store">
                <a href="https://www.slingbelt.com/" target="_blank" rel="noreferrer">
                  <div class="inr_hx">Online Store</div>
                  <div class="inr_text">
                    <span class="top2401__header_arrow01"></span>製品のご購入
                  </div>
                </a>
              </div>

              <button onClick={drawerOpen} className="box_hm">
                <img src="https://www.datocms-assets.com/45449/1702951141-top_header_btn_hm-01.svg" alt="メニューはこちら" />
              </button>
            </div>
            {/*<!-- /.area_nav --*/}
          </div>
          {/*<!-- /.top2401__header -->*/}
        </header>
      );
    }}
  />
);

export default Header;
