import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

const Drawer = () => (
  <StaticQuery
    query={graphql`
      {
        datoCmsHome {
          seo {
            title
            description
            twitterCard
          }
          icon {
            fluid(maxWidth: 300, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          copyright
        }
      }
    `}
    render={(data) => {
      const drawerClose = () => {
        const drawer = document.querySelector(".lp_container");
        drawer.classList.remove("open");
      };
      return (
        <>
          <div className="drawer_menu">
            <button className="closebtn" onClick={drawerClose}>
              <Img fluid={data.datoCmsHome.icon[0].fluid} alt="close " />
            </button>
            <p>
              <Link to="/company/">
                会社概要<span class="top2401__header_arrow01"></span>
              </Link>
            </p>
            <p>
              <Link to="/logisticsequipment/">
                物流機器事業本部<span class="top2401__header_arrow01"></span>
              </Link>
            </p>
            <p>
              <Link to="/software-division/">
                ソフトウェア事業部<span class="top2401__header_arrow01"></span>
              </Link>
            </p>
            <p>
              <Link to="/ec-solution-division/">
                ECソリューション事業部<span class="top2401__header_arrow01"></span>
              </Link>
            </p>
            <p>
              <Link to="/iot-division/">
                IoT事業部<span class="top2401__header_arrow01"></span>
              </Link>
            </p>
            <p>
              <Link to="/news/">
                ニュースリリース<span class="top2401__header_arrow01"></span>
              </Link>
            </p>
            <p>
              <Link to="/recruit/">
                採用情報<span class="top2401__header_arrow01"></span>
              </Link>
            </p>
            <p>
              <Link to="/contact/">
                お問い合わせ<span class="top2401__header_arrow01"></span>
              </Link>
            </p>
          </div>
        </>
      );
    }}
  />
);

export default Drawer;
