import React, { useEffect, useRef } from 'react';
import Header from "./header"
import Footer from "./footer"
import Drawer from "./drawer"
import Seo from "./seo"
import "@fontsource/noto-sans-jp"


const Layout = ({children, pagestyle, pathname, seo}) =>{
    const mainTagClass = pagestyle ? `${pagestyle.titleClass}`: ""
    const containerName = `lp_container ${mainTagClass}`
    const target = useRef(null);

    useEffect(()=>{
      const cb = (entries) => {
        entries.forEach((entry)=>{
          const headcheck = document.querySelector('header')
          if(entry.isIntersecting){
            //クラスの付け替え
            headcheck.classList.add("on")
          }else {
            //クラスの付け替え
            headcheck.classList.remove("on")
          }
        })
      };
      const options = {
        root:null,
        rootMargin: "200px 0px 0px 0px",
      }
      const io = new IntersectionObserver(cb, options);
      io.observe(target.current)
    },[])
    return (
      <>
        <div className={pagestyle ? containerName: "lp_container"} >
        <Seo postData={seo}/>
        <div className="description" ref={target}></div>
        <Header h1data={seo}/>

        <main className={mainTagClass}>
            {children}
            {pathname}
        </main>
        <Footer/>
        <Drawer/>
        </div>
      </>
    )
  }
  
  export default Layout;