import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

const Footer = () => (
  <StaticQuery
    query={graphql`
      {
        datoCmsHome {
          seo {
            title
            description
            twitterCard
          }
          copyright
        }
        datoCmsImage {
          topimage {
            url
            pc: fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
              ...GatsbyDatoCmsFluid
            }

            sp: fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "800", h: "1200" }) {
              ...GatsbyDatoCmsFluid
            }
            business_unit: fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "600", h: "370" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    `}
    render={(data) => {
      const imageData = data.datoCmsImage;
      const image = [];
      imageData.topimage.forEach((e, key) => {
        image[key] = [e.pc, { ...e.sp, media: `(max-width: 768px)` }];
      });
      return (
        <>
          <div className="business_unit">
            <div>
              <Link to="/company/">
                <div className="unitbox">
                  <Img fluid={imageData.topimage[10].business_unit} alt="会社概要" />
                </div>
                <div className="unitname">
                  <span>COMPANY</span>
                  <span>会社概要</span>
                </div>
              </Link>
            </div>
            <div>
              <Link to="/logisticsequipment/">
                <div className="unitbox">
                  <Img fluid={imageData.topimage[8].business_unit} alt="物流機器事業部" />
                </div>
                <div className="unitname">
                  <span>LOGISTICS</span>
                  <span>物流機器事業部</span>
                </div>
              </Link>
            </div>
            <div>
              <Link to="/software-division/">
                <div className="unitbox">
                  <Img fluid={imageData.topimage[31].business_unit} alt="ソフトウェア事業部" />
                </div>
                <div className="unitname">
                  <span>SOFTWARE</span>
                  <span>ソフトウェア事業部</span>
                </div>
              </Link>
            </div>
            <div>
              <Link to="/ec-solution-division/">
                <div className="unitbox">
                  <Img fluid={imageData.topimage[5].business_unit} alt="ECソリューション事業部" />
                </div>
                <div className="unitname">
                  <span>EC SOLUTION</span>
                  <span>ECソリューション事業部</span>
                </div>
              </Link>
            </div>
            <div>
              <Link to="/iot-division/">
                <div className="unitbox">
                  <Img fluid={imageData.topimage[29].business_unit} alt="IoTソリューション事業部" />
                </div>
                <div className="unitname">
                  <span>IoT SOLUTION</span>
                  <span>IoTソリューション事業部</span>
                </div>
              </Link>
            </div>
          </div>

          <footer>
            <div>
              <p className="title">MAIN OFFICE</p>
              <p>
                〒465-0058
                <br />
                愛知県名古屋市名東区貴船1-346
              </p>
              <p>
                TEL：<span className="pctel">052-701-6711</span>
                <a className="sptel" href="tel:0527016711">
                  052-701-6711
                </a>{" "}
                / FAX：052-701-6722
              </p>
              <p className="freedialarea">
                物流機器事業本部
                <br />
                お客様センターフリーダイヤル
                <br />
                <span className="freedial">
                  <span className="pctel">0120-947-576</span>
                  <a className="sptel" href="tel:0120947576">
                    0120-947-576
                  </a>
                </span>
              </p>
            </div>
            <div>
              <p className="title">Product information</p>
              <p>
                <Link to="/slingbelt/">スリングベルト</Link>
              </p>
              <p>
                <Link to="/roundsling/">ラウンドスリング</Link>
              </p>
              <p>
                <Link to="/lashingbelt/">ラッシングベルト</Link>
              </p>
              <p>
                <Link to="/cum-buckle/">25mmベルト</Link>
              </p>
            </div>
            <div>
              <p className="title">Business introduction</p>
              <p>
                <Link to="/logisticsequipment/">物流機器事業本部</Link>
              </p>
              <p>
                <Link to="/software-division/">ソフトウェア事業部</Link>
              </p>
              <p>
                <Link to="/ec-solution-division/">ECソリューション事業部</Link>
              </p>
              <p>
                <Link to="/iot-division/">IoT事業部</Link>
              </p>
              <p className="title">Others</p>
              <p>
                <Link to="/contact/">お問い合わせ</Link>
              </p>
              <p>
                <Link to="/recruit/">採用情報</Link>
              </p>
            </div>
            <div className="copyright">copyright (c) AVIELAN Co., Ltd. All Rights Reserved</div>
          </footer>
        </>
      );
    }}
  />
);

export default Footer;
