import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';



const SEO = ({ postData, frontmatter = {}, postImage, isBlogPost }) => (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              description
              siteUrl
              
            }
          }
        }
      `}
      render={({ site: { siteMetadata: seo } }) => {
        const postMeta = postData ? postData : {};
        //   frontmatter || postData.childMarkdownRemark.frontmatter || {};
        const title = postData ? postMeta.title : seo.title;
        const description = postData ? postMeta.seo.description : seo.description;
        const image = postData ? postMeta.seo.image ? postMeta.seo.image.url: seo.image : seo.image;
        const url = postMeta.slug
          ? `${seo.siteUrl}/${postMeta.slug}/`
          : seo.siteUrl;
        // const datePublished = isBlogPost ? postMeta.datePublished : false;
        return (
          <React.Fragment>
            <Helmet>
              <html lang="ja" />
              {/* General tags */}
              <title>{title}</title>
              <meta name="description" content={description} />
              <meta name="image" content={image} />
              <link rel="canonical" href={url} />
  
              {/* OpenGraph tags */}
              <meta property="og:url" content={url} />
              {isBlogPost ? <meta property="og:type" content="article" /> : null}
              <meta property="og:title" content={title} />
              <meta property="og:description" content={description} />
              <meta property="og:image" content={image} />
  
              {/* Twitter Card tags */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={title} />
              <meta name="twitter:description" content={description} />
              <meta name="twitter:image" content={image} />
              <script defer src="https://polyfill.io/v3/polyfill.min.js?features=fetch%2CPromise"></script>
            </Helmet>

          </React.Fragment>
        );
      }}
    />
  );

export default SEO;